.content {
    width: 95%;
    height: 90vh;
    overflow-y: auto;
    /* padding: 50px 58px; */
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.container {
    padding: 2vh 2%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding-top: 30px;
}

.modalTitle {
    margin-bottom: 10px;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 24px;
    color: #ff6b08;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 0.3px;
    font-size: 22px;
    text-align: center;
}

.imageContainer {
    display: flex;
    justify-content: space-between;
}

.imgcv {
    width: 49%;
    height: auto;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 0 0 1rem 0;
}

.headerRigthSide {
    width: 49%;
    position: relative;
}

.headerLeftSide {
    width: 49%;
}

.headerTitle {
    margin-bottom: 10px;
    font-size: 17px;
    letter-spacing: 1px;
    line-height: 24px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 0.3px;
    font-size: 22px;
    text-align: center;
}

.header p {
    font-size: 14px;
    line-height: 26px;
    color: #2a2a2a;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.text {
    width: 800px;
    margin: 20px auto 38px;
    font-size: 17px;
    letter-spacing: 1px;
    line-height: 28px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.3px;
    font-size: 22px;
}

.textDay4 {
    font-size: 14px;
    line-height: 26px;
    color: #2a2a2a;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    text-align: center;
    margin-top: 25px;
}

.button {
    font-size: 18px;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 15px;
    background-color: #ff6b08;
    border: 0;
    outline: none;
    letter-spacing: 0.3px;
    display: block;
    margin: 1rem auto 0;
    cursor: pointer;
}

.winnerCup {
    position: absolute;
    top: -55px;
    left: 10px;
    width: 80px;
    height: 90px;
}