.code_container {
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: space-between;
    /* overflow-x: hidden;
    overflow: hidden;
    padding-top: 1.5rem; */
    position: relative;
}

.htmlOrCssWrapper {
    width: 100%;
    height: 40vh;
    position: relative;
}

.switchingList {
    position: absolute;
    top: -24px;
    left: -1px;
    display: flex;
    list-style: none;
}

.switchingListItem {
    /* color: black;
    font-size: 14px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600; */
    margin-right: 3px;

    font-size: 13px;
    border: 1px solid #c1c1c1;
    /* border-top-right-radius: 10px;
    border-top-left-radius: 10px; */
    outline: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 5px 8px;
    letter-spacing: 0.3px;
    cursor: pointer;
}

.activeswitchingListItem {
    font-size: 13px;
    border: 1px solid #c1c1c1;
    /* border-top-right-radius: 10px;
    border-top-left-radius: 10px; */
    outline: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 4px 5px;
    letter-spacing: 0.3px;

    padding: 5px 8px;
    color: #ffffff;
    background-color: #1b4371;
    border: 1px solid #1b4371;
    margin-right: 3px;
}

.box {
    width: 100%;
    height: 40vh;
    position: relative;
}

.containerCode {
    width: 100%;
    height: 40vh;
}

.boxCss {
    width: 100%;
    height: 40vh;
    /* position: relative; */
    /* overflow-x: hidden; */
}

.containerHTML {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40vh;
}

.containerCSS {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 45vh;

    /* color: rgb(27, 57, 94); */
}

.codeTitle {
    position: absolute;
    top: -17px;
    left: 0;
    color: black;
    font-size: 14px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

.jsButton {
    position: absolute;
    top: -24px;
    right: 0;
    font-size: 14px;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 2px 16px;
    background-color: #ff6b08;
    border: 0;
    outline: none;
    letter-spacing: 0.3px;
    cursor: pointer;
    border: 1px solid#ff6b08;
}

.disableJsButton {
    composes: jsButton;
    background-color: #fff;
    color: #e0e2e6;
    border: 1px solid#e0e2e6;
    pointer-events: none;
    cursor: pointer;
}

.jsButton:hover {
    background-color: #e96105;
}

@media (max-width: 1200px) {
    .jsButton {
        position: absolute;
        top: -18px;
        right: 0;
        font-size: 10px;
        color: #fff;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        text-align: center;
        padding: 3px 4px;
        background-color: #ff6b08;
        border: 0;
        outline: none;
        letter-spacing: 0.3px;
        cursor: pointer;
        border: 1px solid#ff6b08;
    }

    .disableJsButton {
        composes: jsButton;
        background-color: #fff;
        color: #e0e2e6;
        border: 1px solid#e0e2e6;
        pointer-events: none;
        cursor: pointer;
    }

    .jsButton:hover {
        background-color: #e96105;
    }
}

@media (max-height: 600px) {
    .code_container {
        width: 100%;
        height: calc(38vh + 1.5rem);
        display: flex;
        justify-content: space-between;
        overflow-x: hidden;
        overflow: hidden;
        padding-top: 18px;
    }

    .switchingList {
        position: absolute;
        top: -17px;
        left: 0;
        display: flex;
        list-style: none;
    }

    .switchingListItem {
        margin-right: 3px;
        font-size: 8px;
        border: 1px solid #c1c1c1;
        outline: none;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        text-align: center;
        padding: 3px 4px;
        letter-spacing: 0.3px;
        cursor: pointer;
    }

    .activeswitchingListItem {
        font-size: 8px;
        border: 1px solid #c1c1c1;
        outline: none;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        text-align: center;
        padding: 3px 4px;
        letter-spacing: 0.3px;
        color: #ffffff;
        background-color: #1b4371;
        border: 1px solid #1b4371;
        margin-right: 3px;
    }

    .codeTitle {
        position: absolute;
        top: -10px;
        left: 0;
        color: black;
        font-size: 8px;
        color: #292929;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
    }


    .jsButton {
        position: absolute;
        top: -18px;
        right: 0;
        font-size: 10px;
        color: #fff;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        text-align: center;
        padding: 2px 8px;
        background-color: #ff6b08;
        border: 0;
        outline: none;
        letter-spacing: 0.3px;
        cursor: pointer;
        border: 1px solid#ff6b08;
    }
}